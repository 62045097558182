import Markdown from "markdown-to-jsx";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../components/Layout";
import Spinner from "../components/Spinner";
import { database } from "../data/db/database";

// Create a context for Markdown files
const markdownFiles = require.context("../blog", true, /\.md$/);

function BlogDetails() {
  const params = useParams();
  const navigate = useNavigate(); // For redirecting
  const [content, setContent] = useState("");
  const [blogData, setBlogData] = useState(null);

  const blogId = parseInt(params.id, 10); // Convert ID to number

  useEffect(() => {
    // Find the blog by ID from the mock database
    const blog = database.blogs.find((blog) => blog.id === blogId);
    if (blog) {
      setBlogData(blog);

      try {
        // Dynamically import the markdown file using require.context
        const filePath = `./${blog.filesource.split("/").pop()}`;
        const markdownFile = markdownFiles(filePath);

        // Fetch the markdown content
        fetch(markdownFile)
            .then((res) => res.text())
            .then((res) => setContent(res))
            .catch((err) => {
              console.error("Error fetching Markdown content:", err);
              // Redirect to /blogs with banner message
              navigate("/blogs", {
                state: { showBanner: true }, // Pass a state to trigger the banner
              });
            });
      } catch (err) {
        console.error("Error loading Markdown file:", err);
        // Redirect to /blogs with banner message
        navigate("/blogs", {
          state: { showBanner: true },
        });
      }
    }
  }, [blogId, navigate]);

  if (!blogData) {
    return <Spinner />; // Show a spinner if the blog data is not yet available
  }

  return (
      <Layout>
        <Helmet>
          <title>{blogData.title}</title>
          <meta name="description" content={`Read about ${blogData.title}.`} />
        </Helmet>
        <Suspense fallback={<Spinner />}>
          <div className="mi-blog-details mi-section mi-padding-top mi-padding-bottom">
            <div className="container">
              <Markdown>{content}</Markdown>
            </div>
          </div>
        </Suspense>
      </Layout>
  );
}

export default BlogDetails;
