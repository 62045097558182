import Mock from "../mock";

const database = {
  information: {
    name: "Kevin Pflugradt",
    online: "Elry",
    aboutContent:
      "I am a C# and Unity developer with experience in SQL and REST APIs. \nIn my free time, I develop Games & Apps, play Games & read Manga.",
    age: 25,
    nationality: "German",
    language: "German, English",
    workStatus: "Looking for a Job",
    socialLinks: {
      anilist: "https://anilist.co/user/Elry/",
      discord: "/contact",
      github: "https://github.com/ElryGH",
      linkedin: "https://www.linkedin.com/in/kevin-pflugradt/",
    },
    brandImage: "/images/brand-image.webp",
    aboutImage: "/images/about-image.webp",
  },
  services: [
    {
      title: "Software Development",
      icon: "code",
      details:
          "Proficient in developing robust and scalable software solutions using C# and XAML.",
    },
    {
      title: "Game Development",
      icon: "game",
      details:
          "Expert in C# and Unity, creating interactive and immersive gaming experiences.",
    },
    {
      title: "API Integration",
      icon: "plug",
      details:
          "Experienced in integrating APIs to enable smooth data exchange between systems.",
    },
  ],
  skills: [
    {
      title: "C#",
      value: 90,
    },
    {
      title: "Unity",
      value: 85,
    },
    {
      title: "SQL",
      value: 80,
    },
    {
      title: "REST API",
      value: 75,
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "Present",
        position: "Looking for a Job",
        company: "None",
        details:
            "Im currently looking for a Job opportunity.",
      },
      {
        id: 2,
        year: "June 2024 - August 2024",
        position: "Support & Programmer",
        company: "prodress Software GmbH",
        details:
            "Support on programming issues with ticket creation.",
      },
      {
        id: 3,
        year: "March 2019 - August 2021",
        position: "Warehouse Specialist",
        company: "Leihfirmen (Tempton/Pro Personal)",
        details:
            "Handling of goods movement.",
      },
      {
        id: 4,
        year: "August 2018 - March 2019",
        position: "Warehouse Specialist",
        company: "Tiplexa GmbH",
        details:
            "Receipt and dispatch of goods.",
      },
      {
        id: 5,
        year: "August 2017 - August 2018",
        position: "Warehouse Specialist",
        company: "Ninkaplast GmbH",
        details:
            "Goods receipt and dispatch, handling of goods movement.",
      },
    ],
    educationExperience: [
      {
        id: 1,
        year: "2021 - 2024",
        graduation: "Application Developer",
        university: "FenTec GmbH",
        details:
            "Development of ERP software using C#, VB6 & MySQL.",
      },
      {
        id: 2,
        year: "2015 - 2017",
        graduation: "Warehouse Specialist",
        university: "Ninkaplast GmbH",
        details:
            "Independent loading and unloading, warehouse maintenance.",
      },
      {
        id: 3,
        year: "2010 - 2015",
        graduation: "Secondary School Diploma",
        university: "Hauptschule Lohfeld",
        details: "",
      },
      {
        id: 4,
        year: "2007 - 2010",
        graduation: "Primary School",
        university: "Grundschule Aspe",
        details: "",
      },
    ],
  },
  blogs: [
    {
      id: 1,
      title: "My Favorite Animes 2021",
      featuredImage: "/images/blog/made-in-abyss.webp",
      filesource: "../../blog/my-current-favorite-animes.md",
      createDay: "15",
      createMonth: "December",
      createYear: "2021",
    },
    {
      id: 2,
      title: "Why I'm Sticking with C# and Unity.",
      featuredImage: "/images/blog/unity-vs-unreal-vs-godot.webp",
      filesource: "../blog/unity-vs-unreal-vs-godot.md",
      createDay: "04",
      createMonth: "December",
      createYear: "2023",
    },
    {
      id: 3,
      title: "Going away from Anime for Mangas.",
      featuredImage: "/images/blog/manga-reading.webp",
      filesource: "../blog/why-im-switching-to-manga.md",
      createDay: "15",
      createMonth: "August",
      createYear: "2024",
    },
    {
      id: 4,
      title: "The Journey Begins: Creating Build n Blast",
      featuredImage: "/images/blog/build-n-blast-development.webp",
      filesource: "../blog/build-n-blast-development.md",
      createDay: "26",
      createMonth: "September",
      createYear: "2024",
    },
  ],
  contactInfo: {
    discord: ["elry.moe"],
    emailAddress: ["mail@elry.moe"],
  },
};


Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});


Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

const reverseBlogs = (blogs) => {
  return blogs.slice().reverse(); // Reverse the array
};

Mock.onGet("/api/blog").reply((config) => {
  const response = reverseBlogs(database.blogs);
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});

export { database }; // Ensure you're exporting `database` correctly