import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Particle from "../components/Particle";
import Socialicons from "../components/Socialicons";
import Spinner from "../components/Spinner";

function Home({ lightMode }) {
  const [information, setInformation] = useState("");

  useEffect(() => {
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Elry's Homepage</title>
        <meta
            name="name"
            content="Elry's Homepage"
        />
        <meta
            name="description"
            content="Homepage from Kevin Pflugradt, or Elry. A C# & Unity Developer."
        />
        <meta property="og:title" content="Elry's Homepage"/>
        <meta property="og:image" content={information.brandImage}/>
      </Helmet>
      <Suspense fallback={<Spinner/>}>
        <div className="mi-home-area mi-padding-section">
          <Particle lightMode={false}/>
          <div className="container">
            <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">
                <h1>
                  Hi, I am{" "}
                  <span className="color-theme">{information.name}</span>
                </h1>
                <h3>
                  Also known as{" "}
                  <span className="color-theme">{information.online}</span>
                </h3>
                <p>{information.aboutContent}</p>
                <Socialicons bordered/>
              </div>
            </div>
            </div>
          </div>
        </div>
      </Suspense>
    </Layout>
  );
}

export default Home;
